import * as _reactDom2 from "react-dom";

var _reactDom = "default" in _reactDom2 ? _reactDom2.default : _reactDom2;

var exports = {};
var m = _reactDom;
{
  exports.createRoot = m.createRoot;
  exports.hydrateRoot = m.hydrateRoot;
}
export default exports;
export const createRoot = exports.createRoot,
      hydrateRoot = exports.hydrateRoot;